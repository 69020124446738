import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';


import withRoot from '../../withRoot';

import Layout from '../../components/Layout/index';
import {navigate} from 'gatsby';
import ConfirmDelete from '../../components/ConfirmDelete';
import {isAdmin} from '../../auth';
import LogTableRecord from '../../components/LogTableRecord';


const styles = theme => ({
    root: {
        marginTop: theme.spacing(3),
    },
    paper: {
        padding: theme.spacing(2),
        minWidth: '600px',
    },
    input: {
        margin: theme.spacing.unit,
    },
    button: {
        margin: theme.spacing.unit,
    },
    radiob: {
        display: 'flex',
        flexDirection: 'row',
        width: 'auto',
        paddingRight: '3em'
    },
    backbutton: {
        float: 'right',
    },
    disabledPaper: {
        backgroundColor: '#e47e001c',
    },
});


class Session extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
    };


    constructor(props) {
        super(props)
        this.axios = this.props.axios

    }

    componentWillMount() {
        this.getSession();
        this.getVictim();
    }

    getVictim = () => {
        this.axios.get('get_victim.php', {
            params: {
                id: this.props.victimID,
                first_name: "",
                last_name: "",
                phone: "",
                email: "",
                national_id: "",
                haridus_tase: "",
            },
        })
            .then(res => {
                console.log(res.data);
                let values = res.data[0]
                Object.keys(values).forEach(function (key, index) {
                    if (values[key] === null) {
                        values[key] = ""
                    }
                });



                this.setState({ victim: values })
                console.log(this.state)

            })
            .catch(err => console.log("search err: ", err))
    };

    getSession() {
        
        this.props.axios.get('get_session.php', {
            params: {
                id: this.props.sessionID,
            }
        }).then(res => {
            const {logs, ...formValues} = res.data;
            this.setState({formValues, logs})
        })
            .catch(err => console.log("search err: ", err))


    }

    handleDeleteOpen = () => {
        this.setState({isDeleteOpen: true});
    };
    handleDeleteClose = () => {
        this.setState({isDeleteOpen: false});
    };
    handleDeleteContinue = () => {
        this.axios.post('delete_session.php', {id: this.props.sessionID})
            .then(res => {
                console.log('delete: ', res);
                navigate('/victim/' + this.props.victimID + '/' + this.props.incidentID);
            });
    };

    updateSession = () => {
        let formData = new FormData();
        formData.append('file', this.state.formValues.upload_file);
        this.axios.post( 'save_file.php',
                formData,
                {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
              }
            ).then(response =>{
          console.log('SUCCESS!!');
          console.log(response.data.message);
          console.log(response.data.image);
          console.log(response.data);
          this.state.formValues.upload_file = response.data.image;
        })
        .catch(function(){
          console.log('FAILURE!!');
          this.state.formValues.upload_file = "";
        });
        this.axios.post("update_session.php", this.state.formValues).then(res => {
            console.log(res)
        });
        this.props.location.state["session"] = this.state.formValues
    };

    componentDidMount() {
        this.state.initialValue = Object.assign({}, this.state.formValues)
    }


    handleSelectChange = event => {
        const formValues = this.state.formValues
        formValues[event.target.name] = event.target.value
        this.setState({formValues});
    };

    downloadFile = event => {
        
    }


    handleChange = event => {
        const formValues = this.state.formValues
        formValues[event.target.id] = event.target.value
        this.setState({formValues});
    };
    handleNumChange = event => {
        const formValues = this.state.formValues
        formValues[event.target.id] = event.target.value.replace(',', '.')
        this.setState({formValues});
    };

    checkboxChange = field => {
        const formValues = this.state.formValues
        formValues[field] = (formValues[field] === 0 || formValues[field] === "") ? 1 : 0;
        this.setState({formValues});
    };
    radioChange = (field, value) => {
        const formValues = this.state.formValues
        formValues[field] = value
        this.setState({formValues});
    };

    state = {
        isDeleteOpen: false,
        editingEnabled: false,
        logs: [],
        formValues: {
            incident_id: this.props.incidentID,
            id: this.props.sessionID,
            kuupaev: "",
            kirjeldus: "",
            sidevahendid: "",
            kriisinoustamine: 0,
            kriisinoustamise_aeg: "",
            juhtuminoustamine: 0,
            vorgustikutoo: 0,
            psuhhonoustamine: 0,
            juhtumikorraldus: 0,
            juuranoustamine: 0,
            tegevused_lapsega: 0,
            tugiteenused: 0,
            naise_majutus: 0,
            laste_arv: 0,
            laste_majutus: 0,
            umarlaud: 0,
            marac: 0,
            perearst_kaasatud: 0,
            emo_kaasatud: 0,
            naistearst_kaasatud: 0,
            politsei_kaasatud: 0,
            prokuratuur_kaasatud: "",
            lastekaitse_kaasatud: "",
            kov_kaasatud: 0,
            tsiviilkohus_kaasatud: 0,
            kriminaalkohus_kaasatud: 0,
            haridusasutus_kaasatud: "",
            mtu_kaasatud: "",
            tuttavad_kaasatud: "",
            markused: "",
            kommentaarid: "",
            tootu_kassa: 0,
            muu_partner: 0,
            rahastus: "",
            upload_file: "",
            upload_file_file: "",
        },
        initialValue: {}
    };

    handleUpdate = event => {
        event.preventDefault()
        this.updateSession()
        this.setState({
            editingEnabled: !this.state.editingEnabled,
            initialValue: Object.assign({}, this.state.formValues)
        })
    }

    render() {
        const checkbox = (id, statename, label) => (
            <FormControlLabel control={
                <Checkbox
                    disabled={!this.state.editingEnabled}
                    checked={statename === 1}
                    onClick={() => {
                        this.checkboxChange(id)
                    }}
                />
            } label={label}/>
        )

        const textfield = (id, label, value, pattern) => (

            <TextField
                disabled={!this.state.editingEnabled}
                label={label}
                className={classes.input}
                value={value}
                onChange={this.handleNumChange}
                id={id}
                inputProps={{pattern: pattern}}
            />
        )

        const {classes} = this.props;
        let dateValue = new Date().toDateInputValue();

        const author = this.state.logs ? this.state.logs.find(log => log.action === "CREATE_SESSION") : undefined;
        const changeLogs = this.state.logs ? this.state.logs.filter(log => log.action === "UPDATE_SESSION") : [];
        const lastChanged = changeLogs.length > 0 ? changeLogs[0] : undefined;

        return <Layout title="">
            <div className={classes.root}>
                <Grid container spacing={3} className={classes.root}>
                    <Grid item xs={12}>
                        <Typography variant="h4" gutterBottom>
                            Sessioon {this.state.victim && `(${this.state.victim.first_name} ${this.state.victim.last_name})`}
                            {
                                <Button
                                    className={classes.backbutton}
                                    variant="contained"
                                    color="primary"
                                    onClick={e => navigate("/victim/" + this.props.victimID + "/" + this.props.incidentID)}

                                >
                                    TAGASI
                                </Button>
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={classNames(classes.paper, {
                            [classes.disabledPaper]: !this.state.editingEnabled,
                        })}>
                            <Grid container
                                direction="column"
                                justify="center"
                                alignItems="center"
                                spacing={8}>
                                <Grid item>
                                    <form className={classes.form} onSubmit={this.handleUpdate}>
                                        <Grid container
                                            direction="row"
                                            justify="center"
                                            spacing={16}>
                                            <Grid item sm={3}>
                                                {author && (
                                                    <>
                                                        <FormControl margin="normal">
                                                            <FormLabel>Autor</FormLabel>
                                                            {author.user_name}
                                                        </FormControl>
                                                        <br/>
                                                    </>
                                                )}
                                                {lastChanged && (
                                                    <>
                                                        <FormControl margin="normal">
                                                            <FormLabel>Viimane muutja</FormLabel>
                                                            {lastChanged.user_name}
                                                        </FormControl>
                                                        <br/>
                                                    </>
                                                )}
                                                <FormControl margin="normal">
                                                    <TextField
                                                        value={this.state.formValues.kuupaev === "" ? dateValue : this.state.formValues.kuupaev}
                                                        disabled={!this.state.editingEnabled}
                                                        id="kuupaev"
                                                        label="Kuupäev"
                                                        type="date"
                                                        onChange={this.handleChange}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </FormControl> <br/>
                                                <FormControl margin="normal" className={classes.textfield}>
                                                    <InputLabel htmlFor="kirjeldus">Kirjeldus</InputLabel>
                                                    <Input
                                                        multiline
                                                        rowsMax="3"
                                                        disabled={!this.state.editingEnabled}
                                                        value={this.state.formValues.kirjeldus}
                                                        onChange={this.handleChange}
                                                        id="kirjeldus"
                                                    >
                                                    </Input>
                                                </FormControl>
                                                <br/>
                                                <FormControl margin="normal" fullwidth>
                                                    <TextField
                                                        disabled={!this.state.editingEnabled}
                                                        label="Märkused"
                                                        multiline
                                                        rowsMax="3"
                                                        value={this.state.formValues.markused}
                                                        onChange={this.handleChange}
                                                        id="markused"
                                                    />
                                                </FormControl>
                                                <FormControl margin="normal" fullWidth>
                                                    <FormLabel>Kas nõustamine toimus sidevahendite abil?</FormLabel>
                                                    <RadioGroup className={classes.radiob}>
                                                        <FormControlLabel control={
                                                            <Radio
                                                                disabled={!this.state.editingEnabled}
                                                                checked={this.state.formValues.sidevahendid === 1}
                                                                onClick={() => this.radioChange("sidevahendid", 1)}/>
                                                        } label="Jah"/>
                                                        <FormControlLabel control={
                                                            <Radio
                                                                disabled={!this.state.editingEnabled}
                                                                checked={this.state.formValues.sidevahendid === 0}
                                                                onClick={() => this.radioChange("sidevahendid", 0)}/>
                                                        } label="Ei"/>
                                                    </RadioGroup>
                                                </FormControl>
                                                <FormControl margin="normal" >
                                                    <Button
                                                        variant="contained"
                                                        component="label"
                                                        disabled={!this.state.editingEnabled}
                                                    >
                                                        <input type="file" class="form-control" id="customFile" onChange={ (e) => this.state.formValues.upload_file_file = e.target.files}/>
                                                    </Button>
                                                    
                                                    <Link href={this.state.formValues.upload_file} onClick= { this.downloadFile()}>Lae alla fail
                                                    </Link>
                                                </FormControl>
                                            </Grid>

                                            <Grid item sm={5}>
                                                <FormControl margin="normal" fullWidth>
                                                    <FormLabel>Osutatud teenused (tundide arv)</FormLabel>
                                                </FormControl>
                                                {textfield("kriisinoustamine", "Esmane nõustamine", this.state.formValues.kriisinoustamine, "\\d*|(\\d+([.,](00?|25|50?|75))?)")}
                                                <FormControl>
                                                    <div className={classes.input}>
                                                        <InputLabel htmlFor="kriisinoustamise_aeg">Esmase nõustamise aeg</InputLabel>
                                                        <Select
                                                            disabled={!this.state.editingEnabled}

                                                            value={this.state.formValues.kriisinoustamise_aeg  === "" ? "Puudub" : this.state.formValues.kriisinoustamise_aeg}
                                                            onChange={this.handleSelectChange}
                                                            inputProps={{
                                                                name: 'kriisinoustamise_aeg',
                                                                id: 'kriisinoustamise_aeg',
                                                            }}>
                                                            <MenuItem value={""}>Puudub</MenuItem>
                                                            <MenuItem value={"08:00-22:00"}>08:00-22:00</MenuItem>
                                                            <MenuItem value={"22:00-08:00"}>22:00-08:00</MenuItem>
                                                            <MenuItem value={"teadmata"}>Teadmata</MenuItem>
                                                        </Select></div>
                                                </FormControl>
                                                {textfield("juhtuminoustamine", "Psühhosotsiaalne nõustamine", this.state.formValues.juhtuminoustamine, "\\d*|(\\d+([.,](00?|25|50?|75))?)")}
                                                {textfield("vorgustikutoo", "Võrgustikutöö", this.state.formValues.vorgustikutoo, "\\d*|(\\d+([.,](00?|25|50?|75))?)")}
                                                {textfield("psuhhonoustamine", "Psüh. nõustamine", this.state.formValues.psuhhonoustamine, "\\d*|(\\d+([.,](00?|25|50?|75))?)")}
                                                {textfield("juhtumikorraldus", "Juhtumikorraldus", this.state.formValues.juhtumikorraldus, "\\d*|(\\d+([.,](00?|25|50?|75))?)")}
                                                {textfield("juuranoustamine", "Juriidiline nõustamine", this.state.formValues.juuranoustamine, "\\d*|(\\d+([.,](00?|25|50?|75))?)")}
                                                {textfield("tegevused_lapsega", "Tegevused lapsega", this.state.formValues.tegevused_lapsega, "\\d*|(\\d+([.,](00?|25|50?|75))?)")}
                                                {textfield("tugiteenused", "Tugiteenused", this.state.formValues.tugiteenused, "\\d*|(\\d+([.,](00?|25|50?|75))?)")}
                                                <FormControl margin="normal" fullWidth>
                                                    <FormLabel>Turvaline ajutine majutus</FormLabel>
                                                </FormControl>
                                                {textfield("naise_majutus", "Naise majutuspäevade arv", this.state.formValues.naise_majutus, "(\\d*)")}
                                                {textfield("laste_arv", "Kaasasolevate laste arv", this.state.formValues.laste_arv, "(\\d*)")}
                                                {textfield("laste_majutus", "Laste majutuspäevade arv", this.state.formValues.laste_majutus, "(\\d*)")}
                                            </Grid>
                                            <Grid item sm={4}>
                                                <FormControl margin="normal">
                                                    <FormLabel>Võrgustikutöö teiste organisatsioonidega</FormLabel>
                                                </FormControl>
                                                <br/>
                                                <FormControl margin="normal">
                                                    <FormLabel>MARAC</FormLabel>
                                                    <RadioGroup className={classes.radiob}>
                                                        <FormControlLabel control={
                                                            <Radio
                                                                disabled={!this.state.editingEnabled}
                                                                checked={this.state.formValues.marac === 1}
                                                                onClick={() => this.radioChange("marac", 1)}/>
                                                        } label="Jah"/>
                                                        <FormControlLabel control={
                                                            <Radio
                                                                disabled={!this.state.editingEnabled}
                                                                checked={this.state.formValues.marac === 0}
                                                                onClick={() => this.radioChange("marac", 0)}/>
                                                        } label="Ei"/>
                                                    </RadioGroup>
                                                </FormControl>
                                                <FormControl margin="normal">
                                                    <FormLabel>Juhtumipõhine ümarlaud (v.a. MARAC)</FormLabel>
                                                    <RadioGroup className={classes.radiob}>
                                                        <FormControlLabel control={
                                                            <Radio
                                                                disabled={!this.state.editingEnabled}
                                                                checked={this.state.formValues.umarlaud === 1}
                                                                onClick={() => this.radioChange("marac", 1)}/>
                                                        } label="Jah"/>
                                                        <FormControlLabel control={
                                                            <Radio
                                                                disabled={!this.state.editingEnabled}
                                                                checked={this.state.formValues.umarlaud === 0}
                                                                onClick={() => this.radioChange("marac", 0)}/>
                                                        } label="Ei"/>
                                                    </RadioGroup>
                                                </FormControl>
                                                <FormControl margin="normal" fullWidth>
                                                    <FormLabel>Juhtumisse kaasatud osapooled</FormLabel>
                                                </FormControl>
                                                <FormControl margin="normal" fullWidth>
                                                    <div>
                                                        {checkbox("perearst_kaasatud", this.state.formValues.perearst_kaasatud, "Perearst")}
                                                        {checkbox("emo_kaasatud", this.state.formValues.emo_kaasatud, "EMO")}
                                                        {checkbox("naistearst_kaasatud", this.state.formValues.naistearst_kaasatud, "Naistearst")}
                                                        {checkbox("politsei_kaasatud", this.state.formValues.politsei_kaasatud, "Politsei")}
                                                        {checkbox("prokuratuur_kaasatud", this.state.formValues.prokuratuur_kaasatud, "Prokuratuur")}
                                                        {checkbox("lastekaitse_kaasatud", this.state.formValues.lastekaitse_kaasatud, "Lastekaitse")}
                                                        {checkbox("kov_kaasatud", this.state.formValues.kov_kaasatud, "KOV sotsiaalabi")}
                                                        {checkbox("kriminaalkohus_kaasatud", this.state.formValues.kriminaalkohus_kaasatud, "Kohus (kriminaalasjas)")}
                                                        {checkbox("tsiviilkohus_kaasatud", this.state.formValues.tsiviilkohus_kaasatud, "Kohus (tsiviilasjas)")}
                                                        {checkbox("haridusasutus_kaasatud", this.state.formValues.haridusasutus_kaasatud, "Haridusasutus")}
                                                        {checkbox("mtu_kaasatud", this.state.formValues.mtu_kaasatud, "MTÜ-d")}
                                                        {checkbox("tuttavad_kaasatud", this.state.formValues.tuttavad_kaasatud, "Sõbrad, sugulased")}
                                                        {checkbox("tootu_kassa", this.state.formValues.tootu_kassa, "Töötukassa")}
                                                        {checkbox("muu_partner", this.state.formValues.muu_partner, "Muu partner")}
                                                        {checkbox("ohvriabi_kaasatud", this.state.formValues.ohvriabi_kaasatud, "SKA EOE - Ohvriabitöötaja")}
                                                        {checkbox("ska_eoe_kaugnoustamine_kaasatud", this.state.formValues.ska_eoe_kaugnoustamine_kaasatud, "SKA EOE - Kaugnõustamisteenus")}
                                                        {checkbox("ska_eoe_sak_kaasatud", this.state.formValues.ska_eoe_sak_kaasatud, "SAK")}
                                                        {checkbox("ska_eoe_inimkaubandus_kaasatud", this.state.formValues.ska_eoe_inimkaubandus_kaasatud, "SKA EOE - Inimkaubanduse teenus")}
                                                        {checkbox("ska_eoe_prostitutsiooni_kaasatud_kaasatud", this.state.formValues.ska_eoe_prostitutsiooni_kaasatud_kaasatud, "SKA EOE - Prostitutsiooni kaasatute teenus ")}
                                                        {checkbox("ska_eoe_vagivallast_loobumine_kaasatud", this.state.formValues.ska_eoe_vagivallast_loobumine_kaasatud, "SKA EOE - Vägivallast loobumise teenus  ")}
                                                        {checkbox("ska_eoe_teised_ntkd_kaasatud", this.state.formValues.ska_eoe_teised_ntkd_kaasatud, "SKA EOE - Teised NTK-d")}
                                                        {checkbox("ska_eoe_muu_kaasatud", this.state.formValues.ska_eoe_muu_kaasatud, "SKA EOE - Muud SKA teenused")}
                                                    </div>
                                                </FormControl>
                                                <FormControl margin="normal" fullWidth>
                                                    <InputLabel id="rahastus" htmlFor="rahastus" >Rahastuse liik</InputLabel >
                                                    <Select
                                                        disabled={!this.state.editingEnabled}
                                                        value={this.state.formValues.rahastus}
                                                        onChange={this.handleSelectChange}
                                                        label="Rahastuse liik"
                                                        placeholder="rahastus"
                                                        labelId="rahastus"
                                                        inputProps={{
                                                            name: 'rahastus',
                                                            id: 'rahastus',
                                                        }}>
                                                        <MenuItem value={"Muu rahastus"}>Muu rahastus</MenuItem>
                                                        <MenuItem value={"NTK rahastus"}>NTK rahastus</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <br/>
                                                <FormControl margin="normal" className={classes.textfield} fullWidth>
                                                    <TextField
                                                        multiline
                                                        fullWidth
                                                        variant="outlined"
                                                        label = "Kommentaarid"
                                                        labelId = "kommentaarid"
                                                        rows="13"
                                                        rowsMax="20"
                                                        disabled={!this.state.editingEnabled}
                                                        value={this.state.formValues.kommentaarid}
                                                        onChange={this.handleChange}
                                                        id="kommentaarid"
                                                    >
                                                    </TextField>
                                                </FormControl>
                                            </Grid>

                                            <Grid container
                                                direction="column"
                                                justify="center"
                                                alignItems="center"
                                                spacing={8}>
                                                <Grid item>

                                                    {!this.state.editingEnabled ?
                                                        <Button
                                                            className={classes.button}
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={e => this.setState({
                                                                editingEnabled: !this.state.editingEnabled
                                                            })}
                                                        >
                                                            MUUDA SESSIOONI ANDMEID
                                                        </Button> : null}
                                                    {!this.state.editingEnabled && isAdmin() ?
                                                        <Button
                                                            className={classes.button}
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={e => {
                                                                this.handleDeleteOpen();
                                                            }}
                                                        >
                                                            KUSTUTA SESSIOON
                                                        </Button> : null}

                                                    {this.state.editingEnabled ?
                                                        <Button
                                                            className={classes.button}
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            SALVESTA
                                                        </Button> : null}

                                                    {this.state.editingEnabled ?
                                                        <Button
                                                            className={classes.button}
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={e => {
                                                                this.setState({
                                                                    editingEnabled: !this.state.editingEnabled,
                                                                    formValues: Object.assign({}, this.state.initialValue)
                                                                });


                                                            }}
                                                        >
                                                            TÜHISTA
                                                        </Button> : null}
                                                </Grid>
                                            </Grid>

                                        </Grid>

                                    </form>
                                </Grid>

                            </Grid>

                        </Paper>
                    </Grid>
                    {isAdmin() && (
                        <Grid item xs={12}>
                            <Paper>
                                <LogTableRecord
                                    record_key="session_id"
                                    record_id={this.props.sessionID}
                                    tableTitle="Sessiooni logid"
                                    axios={this.props.axios}
                                />
                            </Paper>
                        </Grid>
                    )}
                </Grid>
                <ConfirmDelete
                    open={this.state.isDeleteOpen}
                    object="sessiooni"
                    onClose={this.handleDeleteClose}
                    onContinue={this.handleDeleteContinue}
                />
            </div>
        </Layout>
    }
}

Session.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(Session));
